import { graphql } from "gatsby"
import React from "react"
import TextTruncate from "react-text-truncate"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TagTemplate = ({ data: { previous, next, blogs, tag, tags } }) => {
  return (
    <Layout>
      <Seo title={tag.title} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section className="intro_text blog_intro blog_posts">
          <div className="container">
            <a className="btn blog_intro__return" href="/blog/">
              BACK TO BLOG
            </a>
            <div className="section__title">{tag.name}</div>

            <div
              className="intro_text_holder blog_posts__holder"
              style={{
                justifyContent: "justify-between",
              }}
            >
              {tags.nodes[0].posts.nodes.map(post => {
                
                return (
                  <div className="blog_posts__post swiper-slide">
                    <div className="blog_posts__post_img">
                      <img src={post.blog.thumbnail.sourceUrl} alt="" />
                    </div>
                    <div className="blog_posts__post_title underline_heading putty">
                      <span>
                        <TextTruncate
                          line={4}
                          breakWord="…"
                          text={post.title}
                        />
                      </span>
                    </div>
                    <div className="blog_posts__post_btn">
                      <a href={post.uri} className="btn">
                        READ MORE
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query TagById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    tag: wpTag(id: { eq: $id }) {
      id
      uri
      name
      contentNodes {
        nodes {
          uri
          id
        }
      }

      id
      name
      contentNodes {
        nodes {
          uri
          id
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    blogs: allWpPost(sort: { fields: [date], order: DESC }, limit: 10) {
      nodes {
        title
        blog {
          thumbnail {
            altText
            sourceUrl
          }
        }
        tags {
          nodes {
            name
            uri
          }
        }
        date
        uri
      }
    }

    tags: allWpTag(filter: { id: { eq: $id } }) {
      nodes {
        uri
        posts {
          nodes {
            title
            uri
            excerpt
            date
            blog {
              thumbnail {
                sourceUrl
              }
            }
            tags {
              nodes {
                name
                uri
              }
            }
          }
        }
      }
    }
  }
`
